<script>
	import { onMount } from "svelte";
	const urlParams = new URLSearchParams(window.location.search);
	const company_slug = urlParams.get("company");

	let company_name = "";
	let summaries = [];
	let list_store = [];
	let list_item = [];
	let list_stock = [];

	onMount(async () => {
		//get company progressing production
		fetch(`${process?.env.BACKEND_HTTP_URL}/api/v1/company/slug/${company_slug}/production`)
			.then(response => response.json())
			.then(response => {
				company_name = response.data.company_name || "";
				summaries = response.data.summaries || [];
			});

		//get all stores with menu quantity
		fetch(`${process?.env.BACKEND_HTTP_URL}/api/v1/company/slug/${company_slug}/store/menu`)
			.then(response => response.json())
			.then(response => {
				list_store = response.data.store || [];
				list_item = response.data.item || [];
				list_stock = response.data.stock || [];
			});
	});

	function convertToCurrency(amount, currency = true) {
		if (currency) {
			const formatter = new Intl.NumberFormat("id-ID", {
				style: "currency",
				currency: "IDR"
			});
			if (amount === null) return "-";
			return formatter.format(amount).replace(/.00$/, "").replace(/,/g, ".");
		}

		return amount ? amount.toLocaleString("id-ID") : amount;
	}
</script>

<main>
	<div id="wrapper">
		<!-- display store with stock -->
		<div align="center">
			<h1>STORE STOCK MONITORING</h1>
			<h3>{company_name}</h3>
		</div>
		<div class="spacer" />
		{#if list_store.length > 0 && list_item.length > 0}
			<table cellpadding="0" cellspacing="0" style="font-size: 0.8em">
				<tr>
					<th rowspan="2">Item SKU</th>
					<th rowspan="2">Item Name</th>
					<th colspan={list_store.length}>Store</th>
				</tr>
				<tr>
					{#each list_store as s}
						<th class={s.store_active_status_name === "inactive" ? "alert" : ""}>{s.store_name}</th>
					{/each}
				</tr>
				{#each list_item as i}
					<tr>
						<td class={i.item_active_status_name === "inactive" ? "alert" : ""}>{i.item_sku}</td>
						<td class={i.item_active_status_name === "inactive" ? "alert" : ""}>{i.item_name}</td>
						{#each list_store as s}
							<!-- baca sesuai format dari backend -->
							<td>{list_stock[`I${i.item_id}S${s.store_id}`] ?? "-"}</td>
						{/each}
					</tr>
				{/each}
			</table>
			<div class="spacer" />
		{/if}

		<!-- display production summary -->
		<div align="center">
			<h1>STORE PRODUCTION MONITORING</h1>
		</div>
		<div class="spacer" />
		{#if summaries.length > 0}
			{#each summaries as s}
				{@const status = s.production.production_completed_status_name === "true" ? "completed" : "progressing"}
				<div class="headbar">
					{s.production.production_name} <br />
					<small class={status === "completed" ? "success" : "info"}>{status}</small>
					<small>{s.production.production_start_datetime} ~ {s.production.production_end_datetime}</small>
				</div>
				<div class="tgrid">
					<div class="tleft">
						<table cellpadding="0" cellspacing="0">
							<tr>
								<th rowspan="2">Material</th>
								<th colspan="4">Quantity</th>
							</tr>
							<tr>
								<th>Purchased</th>
								<th>Used (Auto)</th>
								<th>Remaining (Auto)</th>
								<th>Remaining (Manual)</th>
							</tr>
							{#each s.material as m}
								<tr>
									<td>{m}</td>
									<td>{convertToCurrency(s.material_purchased[m], false) ?? 0}</td>
									<td>{convertToCurrency(s.material_used[m], false) ?? 0}</td>
									<td>{convertToCurrency(s.material_current[m], false) ?? 0}</td>
									<td>{convertToCurrency(s.material_remain[m], false) ?? 0}</td>
								</tr>
							{/each}
						</table>
					</div>
					<div class="tright">
						<table cellpadding="0" cellspacing="0">
							<tr>
								<th rowspan="2">Item</th>
								<th colspan="2">Production</th>
							</tr>
							<tr>
								<th>Target</th>
								<th>Current</th>
							</tr>
							{#each s.item as i}
								{@const target = s.target[i] ?? 0}
								{@const stock = s.stock[i] ?? 0}
								<tr>
									<td>{i}</td>
									<td>{convertToCurrency(target, false)}</td>
									<td class={target > stock ? "alert" : ""}>{convertToCurrency(stock, false)}</td>
								</tr>
							{/each}

							{#if s.item.length > 0}
								<tr>
									<td style="text-align: right;">TOTAL :</td>
									<td class="score">{convertToCurrency(s.total_target, false)}</td>
									<td class="score">{convertToCurrency(s.total_stock, false)}</td>
								</tr>
								<tr>
									<td style="text-align: right;">RATIO :</td>
									<td colspan="2" class="score">{((s.total_stock / s.total_target) * 100).toFixed(2)}%</td>
								</tr>
							{/if}
						</table>
					</div>
				</div>
				<div class="spacer" />
			{/each}
		{/if}
	</div>
</main>

<style>
	@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

	* {
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0;
		margin: 0;
	}
	h1 {
		background-color: #eaeaea;
		margin: 0;
		padding: 5px 10px;
		width: fit-content;
		border-radius: 10px;
	}
	.alert {
		background-color: red;
		color: white;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
	}
	.info {
		background-color: rgb(32, 118, 199);
		color: white;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
	}
	.spacer {
		height: 40px;
	}
	.tgrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "tleft tright";
	}
	.tleft {
		grid-area: tleft;
	}
	.tright {
		grid-area: tright;
	}
	table {
		width: 100%;
		text-align: center;
		background-color: white;
		margin: 0;
		padding: 0;
	}
	th {
		background-color: rgb(189, 188, 188);
		/* color: white; */
		padding: 10px;
		/* text-transform: uppercase; */
		border: 1px solid white;
	}
	td {
		border: 1px solid #ccc;
		padding: 15px 10px;
		font-size: 1.2em;
	}
	.score {
		font-weight: bold;
		font-family: Verdana, Geneva, Tahoma, sans-serif;
		font-size: 2em;
		text-align: center;
	}
	.headbar {
		padding: 10px;
		font-size: 26px;
		text-align: left;
	}
	small {
		font-size: 0.7em;
	}
</style>
